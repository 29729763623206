import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertService } from '@core/services';
import { map, Observable } from 'rxjs';

@Injectable()
export class ErrorResponseHttpInterceptor implements HttpInterceptor {
  private readonly errores = [500];
  private $alertService = inject(AlertService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((response) => {
        if (response instanceof HttpResponse) {
          if (response.url.includes('caranty')) {
            try {
              const body = response.body;

              if (this.errores.includes(body?.code) || body?.status === 'error') {
                const html = `<div class="flex flex-col gap-4"><p>URL: ${response.url}</p> <p>Respuesta: <code>${JSON.stringify(body)}</code></p></div>`;

                /*this.$alertService.customError(
                  html,
                  'OCURRIO UN ERROR DE COMUNICACIÓN, INFORMA AL ADMINISTRADOR:',
                  'error'
                );*/

                console.log(html);
              }
            } catch (e) {
              this.$alertService.customError('Ocurrio un error inesperado', 'ERROR', 'error');
            }
          }
        }
        return response;
      })
    );
  }
}
