import { ActionReducerMap } from '@ngrx/store';
import { CatalogueSaleList } from './contratos/models/contrato.model';
import { detailContratoReducer, showroomReducer } from './contratos/contratos-v2/store/contratos.reducers';
import { ItemCarantyHostCRM, ListEstado, ListShowroom, ShowRoomStateAll } from '@core/models/responsehttp.model';
import { AsociadoObject, ContratoDetail } from './contratos/models/contrato-mc.model';

import { stateGlobalReducer } from '@core/storeGlobal/stateGlobal.reducers';
import { UserSession } from '@core/models/user-roles.model';
import { ProfileType } from '@core/models/utils.model';
import { CarFirebaseObject } from '@core/models/car-firebase.model';
import { ExpedienteEffects, ExpedienteState, stateExpedienteReducer } from '@expediente/store';
import { TallerInspeccionObject } from './pagos-inspeccion/models/pagos-inspeccion.model';
import { stateUserReducer, UserEffects } from './clientes/state';
import { StateVentanasApi, ventanasApiReducer } from './ventanas/store/ventanas.reducer';
import { NotificationEffects, notificationReducer, StateNotificationContratos } from '@notification/store';
import { ContratosEffects } from '@contratos/contratos-v2/store';
import { StateGlobalEffects } from '@core/storeGlobal';
import { VentanasEffects } from './ventanas/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

export interface SaleState {
  loading: boolean;
  catalogueSaleList: CatalogueSaleList;
}

export interface ShowroomState {
  loading: boolean;
  loadingInit: boolean;
  showrooms: ListShowroom[];
}

export interface DetailContratoState {
  loading: boolean;
  contrato: ContratoDetail;
  savedCar: boolean;
  savedBuyer: boolean;
  savedSeller: boolean;
  savedSale: boolean;
  updateSale: boolean;
  generatePDF: boolean;
  asociate: AsociadoObject[];
}

export interface GlobalState {
  state?: ListEstado[];
  showroomsAll?: ShowRoomStateAll[];
  loadedState: boolean;
  carantyhostCRM?: ItemCarantyHostCRM[];
  listAllCHostCompany?: ProfileType[];
  listCHostAquienPuedeVer?: ProfileType[];
  listCarantyHostsGraph?: ProfileType[];
  listChostMiEquipo?: ProfileType[]; //Cuando es Gerente
  loadedCarantyhostCRM: boolean;
  banks?: string[];
  loadedBanks: boolean;
  userSession: UserSession;
  loadingGraph?: boolean;
  resourcesCRM?: any;
  carsPublish?: CarFirebaseObject[];
  talleresInspeccion?: TallerInspeccionObject[];
}

export interface NotificationState {}

export interface UserState {}

export interface AppState {
  showroomState: ShowroomState;
  contratoState: DetailContratoState;
  globalState: GlobalState;
  expedienteState: ExpedienteState;
  userState: UserState;
  ventanasState: StateVentanasApi;
  notificationContratosState: StateNotificationContratos;
  router: RouterReducerState<any>;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  showroomState: showroomReducer,
  contratoState: detailContratoReducer,
  globalState: stateGlobalReducer,
  expedienteState: stateExpedienteReducer,
  userState: stateUserReducer,
  ventanasState: ventanasApiReducer,
  notificationContratosState: notificationReducer,
  router: routerReducer,
};

export const ROOT_EFFECTS = [
  ContratosEffects,
  StateGlobalEffects,
  ExpedienteEffects,
  UserEffects,
  VentanasEffects,
  NotificationEffects,
];
