import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { CatalogoVentanasApiActions, CatalogoVentanasSelectors } from './store';
import { map, Observable } from 'rxjs';

export const initStoreCatalogueVentanasResolver: ResolveFn<boolean> = (): Observable<boolean> => {
  const store = inject(Store);
  const $ventanasLoaded = store.select(CatalogoVentanasSelectors.selectVentanasLoaded);

  return $ventanasLoaded.pipe(
    map((loaded) => {
      if (!loaded) store.dispatch(CatalogoVentanasApiActions.initCatalogueVentanas());
      return true;
    })
  );
};
