import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, inject, AfterViewInit, enableProdMode } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';
import { SubSink } from 'subsink';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { AuthService } from '@auth/auth.service';
import { AlertService } from '@core/services/alert.service';
import { Store } from '@ngrx/store';
import { GlobalApiActions, GlobalSelectors } from '@core/storeGlobal';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Router, RouterOutlet } from '@angular/router';
import { GraphService } from '@core/services/graph.service';
import { ProfileType } from '@core/models/utils.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';
import { LoaderComponent } from '@shared//loader/loader.component';
//import { WebsocketService } from './notificaciones-ws/services/websocket.service';

@Component({
  selector: 'app-root',
  template: `@if (!isIframe) {
      <router-outlet />
    }
    <ic-loader /> `,
  standalone: true,
  imports: [RouterOutlet, LoaderComponent],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  private readonly store = inject(Store);
  private authService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  //private _translateService = inject(TranslateService);
  public primeNGConfig = inject(PrimeNGConfig);
  private _location = inject(Location);
  private storeService = inject(StoreLocalStorageAuthService);
  public _authService = inject(AuthService);
  private _alertService = inject(AlertService);
  public environment = environment.production;
  private router = inject(Router);
  private readonly _destroying$ = new Subject<void>();
  private readonly httpClient = inject(HttpClient);
  private _graphService = inject(GraphService);
  private readonly utilsObserver = inject(LoaderAndUtilsService);
  isIframe = false;
  locationInitialized: string;

  private intervaleDelete: any;

  constructor() {
    if (environment.production) {
      enableProdMode();
      if (window) {
        window.console.log = function () {};
        window.console.error = function () {};
        window.console.info = function () {};
        window.console.warn = function () {};
        window.console.time = function () {};
        window.console.timeEnd = function () {};
      }
    }

    this.locationInitialized = this._location.path();

    localStorage.removeItem('list-scopes');

    //Limpiar cache y unregister service worker PWA
    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }

    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();

          setTimeout(function () {
            console.log('trying redirect do');
            window.location.replace(window.location.href); // because without redirecting, first time on page load: still service worker will be available
          }, 3000);
        }
      });
    }

    //Se ejecuta cada 20 minutos
    this.intervaleDelete = setInterval(() => this.checkedVersionApp(), 1000 * 60 * 20);
  }

  private config!: { version: string };

  ngOnInit(): void {
    //this._wsService.resetChannel();
    this.config = require('./../assets/config.json');
    this.utilsObserver.versionApp$.next(this.config.version);

    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.subs.add(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
          )
        )
        .subscribe((result: EventMessage) => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/login';
          } else {
            this.setLoginDisplay();
          }
        }),

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        })

      /*this._translateService.stream('primeng-es').subscribe((data) => {
        this.primeNGConfig.setTranslation(data);
      })*/
    );
  }

  ngAfterViewInit(): void {
    this.checkedVersionApp();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.store.dispatch(GlobalApiActions.initLoadResorces());

      const loadingUserSession$ = this.store.select(GlobalSelectors.selectUserSession);

      this.subs.add(
        loadingUserSession$.subscribe(({ profileGraph, profileToken }) => {
          if (profileGraph && profileToken) {
            if (profileGraph.userPrincipalName) {
              this.store.dispatch(GlobalApiActions.initListChostAllCompany({ email: profileGraph.userPrincipalName }));
            } else {
              this._alertService.error('User principal name is undefined');
            }

            this.storeService.setDataToGetNewToken(profileToken);

            //Arrancar el socket en el canal del usuario -- Aun esta en pruebas
            //this._wsService.joinChannel(`CRM_${profileGraph.id}_${environment.production ? 'PROD' : 'DEV'}`);

            this.subs.add(
              this._authService.loginApiToken(profileToken).subscribe((isLogin) => {
                if (!isLogin) this._alertService.error('Error al iniciar sesión en la aplicación Azure-CRM');
              })
            );
          }
        }),

        this._graphService.getDataMeProfile().subscribe((profile: ProfileType) => {
          const profileToken = {
            uniqueId: profile.id,
            username: profile.userPrincipalName,
            name: profile.displayName,
            environment: 'login.app.web',
          };

          this.storeService.setDataToGetNewToken(profileToken);
        })
      );
    } else this.router.navigate(['/login']);
  }

  checkedVersionApp() {
    const headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');

    this.utilsObserver.notLoading$.next(true);

    this.httpClient.get<{ version: string }>('/assets/config.json', { headers }).subscribe({
      next: (data) => {
        console.log('Version App:', data.version);
        if (this.config.version !== data.version) this.utilsObserver.isNewVersion$.next(true);
      },
      error: (error) => {},
      complete: () => {
        this.utilsObserver.notLoading$.next(false);
      },
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    clearInterval(this.intervaleDelete);
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
