import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
  withViewTransitions,
} from '@angular/router';

import { APP_ROUTES } from './app.routes';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { initializerApp } from '@core/initializer/initializer-app';

import { CustomHttpInterceptor } from '@core/interceptors/custom.http.interceptor';
import { GlobalErrorHandler } from '@core/handler/global-error-handler';
import { provideStore } from '@ngrx/store';
import { ROOT_EFFECTS, ROOT_REDUCERS } from './app.state';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideLottieOptions } from 'ngx-lottie';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideClientHydration } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MsalConfigAuth } from '@core/graph/graph.provider.config';
import { provideRouterStore } from '@ngrx/router-store';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomSerializer } from '@core/storeGlobal/custom-route-serializer';
import { ErrorResponseHttpInterceptor } from '@core/interceptors/error-response.interceptor';

registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules), withComponentInputBinding(), withViewTransitions()),
    provideNoopAnimations(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseHttpInterceptor,
      multi: true,
    },

    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    ...MsalConfigAuth,
    initializerApp,
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideEnvironmentNgxMask(),
    provideClientHydration(),
    provideStore(ROOT_REDUCERS),
    provideRouterStore({ serializer: CustomSerializer }),
    provideEffects(ROOT_EFFECTS),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'auto', SubscriptSizing: 'dynamic' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
};
