<div class="sidenav flex h-full w-full flex-col shadow-md">
  <div class="flex justify-center pb-4 pt-6 text-center">
    <img
      src="../../assets/img/logo-blanco.png"
      width="180"
      height="180"
      alt="Logo de I-Caranty"
      class="cursor-pointer"
      routerLink="/dashboard"
      priority />
  </div>

  <div class="flex items-center justify-center">
    <span class="mb-4 cursor-pointer text-center text-4xl font-extrabold tracking-[0.4em] text-white" routerLink="/">
      CRM
    </span>

    <span class="text-sm text-white"> v{{ versionApp$ | async }} </span>
  </div>

  <div class="mb-4 ml-3 flex flex-col">
    <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.givenName }}</span>
    <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.surname }}</span>
    <span class="font-semibold text-ica-primary">{{ dataProfile?.userPrincipalName }}</span>
  </div>

  <ul class="ml-3 mr-2 flex h-3/4 flex-col">
    @for (menu of menu; track menu) {
      <li
        [ngClass]="{
          'my-1 pl-2': menu.type !== 'modulo',
          'border-t-2 border-t-zinc-200 py-2': menu.type === 'modulo',
        }">
        @if (menu.inProduction && environment) {
          <ng-container [ngTemplateOutlet]="item"> </ng-container>
        }
        @if (!environment) {
          <ng-container [ngTemplateOutlet]="item"> </ng-container>
        }
        <ng-template #item>
          @if (menu.routerLink) {
            <a mat-button [routerLink]="menu.routerLink" routerLinkActive="active" class="flex content-center">
              <!--[routerLinkActiveOptions]="{exact: true}" -> se desactiva la ruta exacta para el activado del menu--->
              <span [ngClass]="menu.type === 'modulo' ? 'li-menu-module' : 'li-menu'">
                {{ menu.label }}
              </span>
            </a>
          }
        </ng-template>
      </li>
    }
  </ul>
</div>
