import { Routes } from '@angular/router';
import { LayoutComponent } from './container/layout/layout.component';
import { RolUserChildGuard } from '@core/guards/rol-user.guard';
import { MsalGuard } from '@azure/msal-angular';
import { initStoreCatalogueVentanasResolver } from './ventanas/init-store-ventanas.resolver';
import { initStoreNotificationResolver } from '@notification/init-store-notification.resolver';

export const APP_ROUTES: Routes = [
  {
    path: 'create-ics-file/:id',
    loadComponent: () => import('./generate-ics/generate-ics.component').then((m) => m.GenerateIcsComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'login-failed',
    loadComponent: () => import('@shared/failed-login/failed.component').then((m) => m.FailedComponent),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MsalGuard, RolUserChildGuard],
    canActivateChild: [MsalGuard, RolUserChildGuard],
    canMatch: [MsalGuard],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'clientes',
        loadChildren: () => import('./clientes/clientes.routes').then((m) => m.CLIENTES_ROUTES),
      },
      {
        path: 'autos',
        loadChildren: () => import('./autos/autos.routes').then((m) => m.AUTOS_ROUTES),
      },
      {
        path: 'transacciones',
        loadChildren: () => import('./transacciones/transacciones.routes').then((m) => m.TRANSACCIONES_ROUTES),
      },
      {
        path: 'publicaciones-v2/citas',
        loadChildren: () => import('./citas/citas.routes').then((m) => m.CITAS_ROUTES),
      },
      {
        path: 'credito',
        loadChildren: () => import('./credito/credito.routes').then((m) => m.CREDITO_ROUTES),
      },
      {
        path: 'calendariov2',
        loadChildren: () => import('./calendario-v2/calendario.routes').then((m) => m.CALENDARIO_ROUTES),
      },
      {
        path: 'publicaciones-v2',
        loadChildren: () =>
          import('./autos/publicaciones-v2/publicacion-auto.routes').then((m) => m.AUTO_PUBLICACION_ROUTES),
      },
      {
        path: 'mesa-control',
        loadChildren: () => import('./mesa-control/mesa-control.routes').then((m) => m.MESA_CONTROL_ROUTES),
      },
      {
        path: 'referencias',
        loadChildren: () => import('./referencias/referencias.routes').then((m) => m.REFERENCIAS_ROUTES),
      },
      {
        path: 'ajustes-admin',
        loadChildren: () => import('./ajustes-admin/ajustes-admin.routes').then((m) => m.AJUSTES_ADMIN_ROUTES),
      },
      {
        path: 'mis-asignados',
        loadChildren: () => import('./mis-asignados/mis-asignados.routes').then((m) => m.MIS_ASIGNADOS_ROUTES),
      },
      {
        path: 'calculadora',
        loadChildren: () => import('./calculadora/calculadora.routes').then((m) => m.CALCULADORA_ROUTES),
      },
      {
        path: 'solicitud-contrato',
        loadChildren: () => import('./contratos/solicitud/solicitud.routes').then((m) => m.SOLICITUD_CONTRATO_ROUTES),
        resolve: {
          initCatalogue: initStoreCatalogueVentanasResolver,
          initStoreNotification: initStoreNotificationResolver,
        },
      },
      {
        path: 'apartados',
        loadChildren: () => import('./apartados/apartados.routes').then((m) => m.APARTADOS_ROUTES),
      },
      {
        path: 'pagos-inspeccion',
        loadChildren: () => import('./pagos-inspeccion/pagos-inspeccion.routes').then((m) => m.PAGOS_INSPECCION_ROUTES),
      },
      {
        path: 'ventanas',
        loadChildren: () => import('./ventanas/ventanas.routes').then((m) => m.VENTANAS_ROUTES),
        resolve: {
          initCatalogue: initStoreCatalogueVentanasResolver,
        },
      },
      {
        path: 'log-audit',
        loadComponent: () => import('./audit-log/audit-log.component').then((m) => m.AuditLogComponent),
      },
      { path: 'home/dashboard', redirectTo: 'dashboard' },
      {
        path: '**',
        loadComponent: () => import('./404-notfound/page-not-found.component').then((m) => m.PageNotFoundComponent),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./404-notfound/page-not-found.component').then((m) => m.PageNotFoundComponent),
  },
];
